module directives {
    export module applicationcore {
        interface IEntityListViewScope extends ng.IScope {
            entityChanged(): void,
            entityClicked(entity: interfaces.applicationcore.IEntityTreeListItem): void,
            collapsed: boolean,
            toggle(): void
        }

        export class entityMenuListDirective implements ng.IDirective {
            scope = {
                entity: "=",
                entityChanged: "&onEntityChanged"
            }

            templateUrl = 'templates/modules/applicationcore/entityMenuView.html';
            restrict = 'E';
            transclude = true;

            constructor(private $timeout, private $rootScope: interfaces.applicationcore.IRootScope,
                private entityService: interfaces.applicationcore.IEntityService, public generalService: interfaces.applicationcore.IGeneralService,
                private $cookies: ng.cookies.ICookiesService) {
            }

            link = ($scope: IEntityListViewScope, $element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
                $scope.collapsed = true;
                $scope.entityClicked = (entity: interfaces.applicationcore.IEntityTreeListItem) => {
                    this.entityService.setUserEntity(entity.Id).save(
                        (msgHandler: interfaces.applicationcore.IMessageHandler) => {
                            this.generalService.displayMessageHandler(msgHandler);
                            this.$cookies.put("activeEntity", String(entity.Id));
                            if (angular.isDefined($scope.entityChanged)) {
                                this.$rootScope.Entity = <interfaces.applicationcore.IEntity>{
                                    Id: entity.Id,
                                    Name: entity.Name,
                                    Code: entity.Code
                                };
                                $scope.entityChanged();
                            }
                        },
                        (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        });
                }

                $scope.toggle = () => {
                    $scope.collapsed = !$scope.collapsed;
                };
            };

            static factory(): ng.IDirectiveFactory {
                const directive = ($timeout, $rootScope, entityService, generalService,$cookies) => new entityMenuListDirective($timeout, $rootScope, entityService, generalService,$cookies);
                directive.$inject = ['$timeout', '$rootScope', 'entityService', 'generalService','$cookies'];

                return directive;
            }
        }

        angular.module("app").directive("gtsEntityMenu", entityMenuListDirective.factory());
    }
}